import Container from '@material-ui/core/Container'
import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import { KeyboardArrowDown as ArrowDownIcon } from '@material-ui/icons'
import { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { FrontpagePopup } from '../components/FrontpagePopup'
import FacebookIcon from '../public/images/facebook.svg'
import InstagramIcon from '../public/images/instagram.svg'
import LinkedinIcon from '../public/images/linkedin.svg'
import MailIcon from '../public/images/mail.svg'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60,
    paddingBottom: 120
  },
  container: {
    maxWidth: '676px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  image: {
    width: '96px',
    height: '96px'
  },
  heading: {
    textTransform: 'uppercase',
    textAlign: 'center',
    width: '100%',
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 20
  },
  subheading: {
    textTransform: 'uppercase',
    textAlign: 'center',
    width: '100%',
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 16,
    marginTop: 16
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 999,
    width: '100%',
    maxWidth: '100%',
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
    cursor: 'pointer',
    fontSize: 16,
    padding: 16
  },
  videoAccordion: {
    width: '100%',
    borderRadius: 32,
    overflow: 'hidden',
    transition: 'all .5s ease-in-out',
    backgroundColor: 'black'
  },
  videoContainer: {
    height: 384
  },
  arrowDownIcon: {
    position: 'absolute',
    top: 16,
    right: 16
  },
  socialsContainer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center'
  },
  social: {
    height: 32,
    width: 32,
    color: theme.palette.primary.main,
    '&:hover': {
      transform: 'scale(1.1)'
    }
  }
}))

const VideoAccordion: React.FC = () => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)

  const height = isOpen ? 384 : 55

  return (
    <div className={classes.videoAccordion} style={{ height }}>
      {isOpen ? (
        <div className={classes.videoContainer}>
          <iframe
            id='guwFLSSnBPo'
            allowFullScreen
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            title='LIVE KLASSISK'
            src='https://www.youtube.com/embed/RhkOlcy9OJc?origin=https%3A%2F%2Fliveklassisk.com&amp;autoplay=1&amp;mute=0&amp;playsinline=1&amp;modestbranding=1&amp;listType=user_uploads&amp;start=0&amp;enablejsapi=1&amp;widgetid=1'
            width='100%'
            height='100%'
            frameBorder={0}
          />
        </div>
      ) : (
        <div
          className={classes.button}
          style={{ position: 'relative' }}
          onClick={() => setIsOpen(true)}
        >
          <div>Trailer</div>
          <ArrowDownIcon className={classes.arrowDownIcon} />
        </div>
      )}
    </div>
  )
}

const LandingPage: NextPage = () => {
  const classes = useStyles()

  return (
    <>
      <Head>
        <title>Live Klassisk</title>
        <meta
          name='description'
          content='Live Klassisk – classical concerts, festivals, ensembles and venues in Scandinavia. Discover classical music with all the greatest artists in the world!'
        />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/liveklassisk.appspot.com/o/uploads%2Flogo%2Fliveklassisk-logo_500x500.jpg?alt=media&token=084060e9-4674-4583-bd40-09cc597c149a'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://liveklassisk.com' />
        <meta property='og:title' content='Live Klassisk' />
        <link rel='canonical' href='https://liveklassisk.com' />
      </Head>
      <div className={classes.root}>
        <Container className={classes.container}>
          <div className={classes.image}>
            <Image
              src='/images/logo_big.png'
              alt='Live Klassisk logo'
              width='96px'
              height='96px'
            />
          </div>
          <div className={classes.heading}>Live Klassisk</div>
          <div style={{ marginBottom: 16, width: '100%' }}>
            <Link href='/dk'>
              <div className={classes.button}>Denmark</div>
            </Link>
          </div>
          <div style={{ marginBottom: 16, width: '100%' }}>
            <Link href='/no'>
              <div className={classes.button}>Norway</div>
            </Link>
          </div>
          <div style={{ marginBottom: 16, width: '100%' }}>
            <Link href='/se'>
              <div className={classes.button}>Sweden</div>
            </Link>
          </div>

          <div className={classes.subheading}>Other</div>
          <div style={{ marginBottom: 16, width: '100%' }}>
            <Link href='/en/news'>
              <div className={classes.button}>English news and articles</div>
            </Link>
          </div>
          <div style={{ marginBottom: 16, width: '100%' }}>
            <a
              style={{ width: '100%' }}
              href='https://mailchi.mp/417fa718322b/sign-up'
            >
              <div className={classes.button}>Newsletter</div>
            </a>
          </div>
          <div style={{ marginBottom: 16, width: '100%' }}>
            <Link href='/app'>
              <div className={classes.button}>Live Klassisk app</div>
            </Link>
          </div>
          <VideoAccordion />

          <div className={classes.socialsContainer}>
            <a
              href='https://www.facebook.com/liveklassisk'
              style={{ marginRight: 12 }}
              target='_blank'
              rel='noreferrer'
            >
              <FacebookIcon className={classes.social} />
            </a>
            <a
              href='https://instagram.com/live_klassisk'
              style={{ marginRight: 12 }}
              target='_blank'
              rel='noreferrer'
            >
              <InstagramIcon className={classes.social} />
            </a>
            <a
              href='https://www.linkedin.com/company/live-klassisk'
              style={{ marginRight: 12 }}
              target='_blank'
              rel='noreferrer'
            >
              <LinkedinIcon
                className={classes.social}
                target='_blank'
                rel='noreferrer'
              />
            </a>
            <a
              href='mailto:info@liveklassisk.dk'
              target='_blank'
              rel='noreferrer'
            >
              <MailIcon
                className={classes.social}
                target='_blank'
                rel='noreferrer'
              />
            </a>
          </div>
          <FrontpagePopup />
        </Container>
      </div>
    </>
  )
}

export default LandingPage
